<template>
	<section class="domain_content">
		<!-- Disclaimer -->
		<div class="sub-page">
			<h1>Disclaimer</h1>
			<p>
        All the information on this website is for general information purposes only. 
        We are not responsible for any damages resulting from the use of our website.
			</p>
			<div class="part">
				<h2>
          About GB WhatsApp Banned Account
				</h2>
				<p>
          WhatsApp strictly prohibits the use of unauthorized or modified versions of its application 
          and actively enforces measures against accounts using such versions. If your account is blocked 
          while using GB WhatsApp or any other unauthorized version, we disclaim all responsibility. 
          We strongly advise using a secondary mobile number for registration and regularly backing up your chat history and multimedia files.
				</p>
				<h2>
          Third-Party Links				
          </h2>
				<p>
          This website may contain links to external sites. Upon navigating to these external pages, you are advised to review 
          the privacy policies and terms of service of the respective sites. We disclaim any responsibility for losses or damages incurred on third-party websites.
				</p>
				<h2>
          Consent
				</h2>
				<p>
          By using our website, you hereby consent to our disclaimer and agree to its terms.
				</p>
				<h2>
				Changes to this Disclaimer
				</h2>
				<p>
          We'll update the Disclaimer regularly. You're required to visit this page to check the changes.
				</p>
			</div>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'Disclaimer'
const description = 'Disclaimer of this site will be updated regularly. By using this Website, you accept this disclaimer in full. '
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
            "link": [{
                "rel": "canonical",
                "href": "https://whatsapppro.com/disclaimer/"
            }]
	},
}
</script>
  